@media (max-width:600px) {
    .arhan{
    background-color:white;

    
    }
    .arhan-social{
        
        display: flex;
    }
    .e-muallim-social{
        display: flex;
        
    }
    .e-muallim{
        background-color:white;
        
    }
    .nippen-social{
        display: flex;

    }
    .nippen{
        background-color:white;
    }
    .la-ribba-social{
        display: flex;
    }
    .la-ribba{
        background-color: white;
    }
    .campus-social{
        display: flex;
    }
    .campus{
        background-color: white;
    }
}