/* 


/* @media (max-width:600px) {
.media{
    
    display: flex;
    flex-direction: row;
    justify-content: start;
}   

.media1{
   
    width: 40%;
    padding: 0px 0px;
    margin: 0px 0px;
    
}
.media3{

  
    
    width: 100%;
    padding: 0px 0px;
    margin: 0px 0px;
}
.media3 img {
    
    width: 45px;
    height: 45px;
        padding: 0px 0px;
    margin: 0px 0px;
}
.media3 span {
    
    padding: 0px 0px;
    margin: 0px 0px;
    font-size: 5px;
    
}
.media3 span strong{
   
    font-size: 5px;
    font-weight: 100;
    height: 50px;
    width: 90px;
    padding: 0px 0px;
    margin: 0px 0px;

}
}
 */

 @media (max-width:768px){
    .mobile-off{
        display: none !important;
    }
    }
  @media (max-width:600px){
   
    .mobile-off{
        display: none !important;
    }

    .media {
      
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: center !important;
        

    }
    .media1{
        flex: 1 !important;

        padding-left: 10px !important;
        padding-right: 10px !important;
width: 100% !important;
    }
    .media2{
        background-color: blue !important;
    }
    .media3 {
       
        display: flex !important;
        flex: 1 !important;
        align-items: center !important;
       margin-bottom: 4vh ;
        width: 100% !important;
        box-sizing: border-box;



    }
    .media3 img{
        width: 63px !important;
    }
   .font{
    font-weight: 800 !important;
   
    font-size: 2.75em !important;
   }
   .font1{
    font-weight: 700 !important;
   }
    .media4{
       
        width: 100%;
        padding: 0 10px !important;

    }
    
 }  