/* .expand{
    transition: max-height 0.9s ease !important;
} */
  

.blog {
    max-height: 200px !important;
    overflow: hidden !important;
    transition: max-height 3s ease-in-out !important;
  }
  .additional-content {
    max-height: 0 !important;
    overflow: hidden !important;
    transition: max-height 0.5s ease-in-out !important;
  }
  



@media (max-width:600px) {


    .artificial-intelligence{
        background-color: white;
    }
    .development{
        background-color: white;
    }
    .automation{
        background-color: white;
    }
    .social-media{
        
        display: flex !important;
        
        flex-direction: column !important;
        background-color: white !important;
    
    }
    .social-media1{
        
        display: flex !important;
        flex-direction: column !important;
    }
    .social-media2{
        
        display: flex !important;
        flex-direction: column !important;
        
    }
}