.support{
   
    font-size: 1.4em !important;
cursor: pointer;
font-weight: 600 !important;
}
.support-num{
    
    font-weight: 700 !important;
}

@media (max-width:600px) {
    .support{
        font-size: 4.5vw !important;
    }
    .support21{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .support22{
        
        display: flex;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .support23{
       
        display: flex;
        margin: 0 !important;
        padding: 0 !important;
        justify-content: left;
        align-items: center;
        font-size: 2.6vw !important;
        font-weight: 100 !important;
        text-decoration: none !important;
    }
}