/* #header {
    height: var(--sticky-header-container-height) !important;
  } */

  .sticky-header {
    position:sticky !important;
    top: 0;
    left: 0;
    width: 100%;
    
    z-index: 1000;
    /* background-color: white !important; */
  }
  