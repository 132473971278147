@media (max-width:600px) {
    .owl-carousel .owl-item  .owl-theme {
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 10px; */
        /* height: 10px; */
      }
      .slide-position{
        
        justify-content: center !important;
        align-items: center !important;
        /* display: none !important; */
       display: inline;
       /* min-width: 100%; */
       text-align: center;
      }

      
}

@media (min-width: 576px) and (max-width: 767px) {
     .slide-position .owl-carousel .owl-item .item{
      background-color: aqua !important;
      margin-right: 50px !important;
     }   
}