@media (min-width: 768px) {

    .owl-mobile{
        display: none !important;
    }
    
}



