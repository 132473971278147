.media21-text{
    text-align: justify;
}


@media (max-width: 600px) {
  
     /* .item{
        width: 100vw;
        height: 100vh;
    }  */
    /* .mediaImg{
        background-color:red;
    } */
.media21{
    
    width: 90vw;
   
}  


.media21-text{
    text-align: justify;
}

}
  
  
  
  
  
  